import React from "react";
import { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Input } from "../../../../componentsv2/Input";
import Button from "../../../../componentsv2/Button";

import MascaraDeCompetencia from "../../Componentes/MascaraDeCompetencia";
import DataPickerAtualizado from "../../../../components/DataPickerAtualizado";

import MascaraDeData from "../../Componentes/MascaraDeData";

import { useSnackbar } from "notistack";

import { api } from "../../../../services/Api";

import converteDataUS from "../../../../utils/converteDataUS";

import useUser from "../../../../hooks/useUser";

const ModalConstruirLote = ({
  open,
  handleClose,
  setAtualizaTabelaFaturamento,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [mesCompetencia, setMesCompetencia] = useState("");
  const [anoCompetencia, setAnoCompetencia] = useState("");
  const [protocolo, setProtocolo] = useState("");
  const [previsao, setPrevisao] = useState(null);
  const [convenio, setConvenio] = useState([]);
  const [dataInicial, setDataInicial] = useState(() => {
    const valueDateI = new Date();
    valueDateI.setDate(valueDateI.getDate() - 10);
    return valueDateI;
  });
  const [dataFinal, setDataFinal] = useState(new Date());
  const [listaConvenio, setListaConvenio] = useState([]);
  const [lote, setLote] = useState("");
  const [valor, setValor] = useState(0);
  const [quantidade, setQuantidade] = useState(0);
  //const [user, setUser] = useState("");

  const usuario = useUser();

  const getConvenios = async () => {
    try {
      const results = await api.get("/v2/faturamento/convenios");
      const listaConvenio = results.data;
      setListaConvenio(listaConvenio);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getConvenios();
  }, []);

  const ultimoLote = async () => {
    try {
      const loteUnico = await api.get("/v2/faturamento/ultimoLote", {
        params: {
          plano: convenio,
        },
      });
      setLote(loteUnico.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (convenio) {
      ultimoLote();
    }
  }, [convenio]);

  const salvarLote = async () => {
    adicionarProtocolo();
    let previs = previsao ? converteDataUS(previsao) : null;
    try {
      await api.post("/v2/faturamento/adicionaLote", {
        data_envio_lote: new Date(),
        codigo: lote,
        plano: convenio,
        protocolo: protocolo,
        data_protocolo: new Date(),
        data_previsao: previs,
        valor: valor,
        quantidade: quantidade,
        em_construcao: true,
        usuario_responsavel: usuario,
      });
      setAtualizaTabelaFaturamento((prevState) => !prevState);
      enqueueSnackbar("lote e protocolo adicionados", {
        variant: "success",
      });
      handleClose(false);
      limparDados();
    } catch (error) {
      console.log(error);
    }
  };

  const adicionarProtocolo = async () => {
    let previs = previsao ? converteDataUS(previsao) : null;
    try {
      await api.post(`/v2/faturamento/salvaProtocolo`, {
        protocolo: protocolo,
        previsao: previs,
        codigo: lote,
        plano: convenio,
        mes_competencia: mesCompetencia,
        ano_competencia: anoCompetencia,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const limparDados = () => {
    setProtocolo("");
    setMesCompetencia("");
    setAnoCompetencia("");
    setConvenio("");
    setValor(0);
    setLote("");
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{ justifyContent: "space-between" }}
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Construir Lote</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => handleClose(false)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                id="usuario"
                label="Usuario"
                value={usuario}
                onChange={(e) => setLote(e.target.value)} // Make lote editable
              />
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={3}>
                <Autocomplete
                  options={listaConvenio?.map((item) => item.convenio)}
                  value={convenio}
                  onChange={(event, value) => {
                    setConvenio(value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Convênio" />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="lote"
                  label="Lote"
                  value={lote}
                  onChange={(e) => setLote(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="quantidade"
                  label="Quantidade"
                  value={quantidade}
                  onChange={(e) => setQuantidade(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <DataPickerAtualizado
                  data={dataInicial}
                  labelName="Data inicial"
                  setData={setDataInicial}
                />
              </Grid>
              <Grid item xs={3}>
                <DataPickerAtualizado
                  data={dataFinal}
                  labelName="Data final"
                  setData={setDataFinal}
                />
              </Grid>
            </Grid>

            <Grid container item spacing={2}>
              <Grid item xs={3}>
                <Input
                  id="protocolo"
                  label="Protocolo"
                  value={protocolo}
                  onChange={(e) => setProtocolo(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="competencia"
                  placeholder="mm/aaaa"
                  label="Competência"
                  defaultValue={
                    mesCompetencia !== ""
                      ? `${mesCompetencia}/${anoCompetencia}`
                      : ""
                  }
                  onBlur={(e) => {
                    const competencia = e.target.value;
                    const [mes, ano] = competencia.split("/");
                    setMesCompetencia(mes);
                    setAnoCompetencia(ano);
                  }}
                  InputProps={{
                    inputComponent: MascaraDeCompetencia,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  id="valor"
                  label="Valor"
                  value={valor}
                  onChange={(e) => setValor(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="previsao"
                  placeholder="dd/mm/aaaa"
                  style={{
                    marginRight: "1rem",
                    minWidth: "6rem",
                  }}
                  label="Data de Previsão"
                  onBlur={(e) => setPrevisao(e.target.value)}
                  defaultValue={previsao || ""}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: MascaraDeData,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: "16px 24px" }}>
        <Grid container item justifyContent="space-between" spacing={2}>
          <Grid item>
            <Button
              color="gray"
              variant="outlined"
              onClick={() => {
                handleClose(false);
                limparDados();
              }}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="green"
              onClick={() => salvarLote()}
            >
              Salvar Lote
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConstruirLote;
